<template>
  <div class="content">
    <Nav></Nav>
    <Navbj></Navbj>

    <div class="cont_inform">
      <div><img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/9.png" alt="">首页 >  <span>关于我们</span></div>
    </div>
    <div class="cont_about">
      <div class="about_list" v-for="(item,index) in list" :key="index">
        <div class="right">
              <p>{{item.title}} <span>{{item.uk}}</span></p>
              <p><img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/1.png" alt=""></p>
              <p>{{item.subtitle}}</p>
              <p v-html="item.info"></p>
            </div>
        <div class="left">
             <img :src="item.img" alt="">
           </div>

      </div>
<!--       <div class="about_xz">-->
<!--         <img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/21.png" alt="">-->
<!--       </div>-->
    </div>
       
    <Btm></Btm>
  </div>
</template>

<script>
import Nav from "@/components/yd-Nav/index"
import Btm from "@/components/yd-Btmfooter/index"
import {gywm} from "@/api/request/all";
import Navbj from "@/components/yd-Nav/nav_bj";
import {mapState} from "vuex";
export default {
  name: "about",
  data(){
    return{
      list:[]
    }
  },
  components:{
    Nav,
    Navbj,
    Btm
  },
  mounted() {
    this.about()
  },
  computed: {
    ...mapState(['user','seo']),
  },
  metaInfo() {
    return {
      title: this.seo[4].title || this.user.indextitle,
      meta: [
        { name: 'description', content: this.seo[4].description ||this.user.drp},
        { name: 'keywords', content:this.seo[4].keywords ||this.user.seo}
      ]
    };
  },
  methods:{
    about(){
      gywm().then((res)=>{
        if (res.status == 1){
          for (let i=0; i<res.data.length; i++){
            res.data[i].info= this.convertNewLinesToBrTags(res.data[i].info);
          }
         this.list=res.data;
        }

      })
    },
    //正则转换
    convertNewLinesToBrTags(text) {
      return text.replace(/(\r\n|\n|\r)/gm, "<br/>");
    },
  }
}
</script>

<style scoped lang="scss">
.content{

  .cont_inform{
    width: 100%;
    height: 1.65rem;
    background: #EEEEEE;
    div{
      width: 100%;
      margin:0 auto;
      height: 1.65rem;
      line-height: 1.65rem;
      img{
        width: 0.53rem;
        height: 0.65rem;
        vertical-align: sub;
        margin-right: 9px;
        margin-left: 0.63rem;
        position: relative;
        top: -0.08rem;
      }
      font-weight: 500;
      font-size: 0.7rem;
      color: #666666;
      span{
        color: #222222;
      }
    }
  }
  .cont_about{
    width: 100%;
    background: #FFFFFF;
    padding: 66px 0;
   .about_list{
      width: 18rem;
      height: 100%;
      margin: 0 auto 90px;
          .left{
         width: 18rem;
         text-align: center;
         img{
           width: 18rem;
           height: 9.57rem;
           border-radius: 8px;
         }
       }
          p:nth-child(1){
            font-weight: 800;
            font-size: 0.9rem;
            color: #222222;
            span{
              font-size: 0.6rem;
              font-weight: 500;
              color: #999999;
            }
          }
          p:nth-child(2){
            width: 1.2rem;
            height: 0.45rem;

          }
          p:nth-child(3){
            width: 4.3rem;
            height: 1.38rem;
            background: #EEEEEE;
            border-radius: 0.2rem;
            font-size: 0.8rem;
            text-align: center;
            line-height: 1.38rem;
            margin: 1rem 0 0.5rem;

          }
          p:nth-child(4){
            width: 18rem;
            font-weight: 500;
            font-size: 0.75rem;
            color: #333333;
            line-height: 1.5rem;
          }

    }

    .about_xz{
      width: 300px;
      height: 66px;
      margin: 0 auto;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }

}
</style>